import  {  useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion";
import PortalContext from "../../../contexts/PortalContext";
import AuthContext from "../../../contexts/AuthContext";
import {  useLogoutMutation } from "../../../generated/graphql";

const variants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};
const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 50,
    opacity: 0,
  },
};

const Links = () => {
  const authContext =useContext(AuthContext);
  const portal = useContext(PortalContext);

  const [logoutMutation,{data,loading,error}] = useLogoutMutation();
  useEffect(() => {
    // console.log("L", data);
    if (data) {
      if (data.logout === 1) {
        if (authContext) {
          authContext.signOut();
        }
      }
    }
  }, [data]);


  const location = useLocation();
  const currentPath = location.pathname;

    const path=/\/home(#.*)?/;
  // const items = ["Home", "Proshows","WorldFest", "Spotlight"];

  return (
    <motion.div className="links" variants={variants}>
      {/* {items.map((item) => (
        <motion.a
          href={currentPath===path?`#${item}`:`home#${item}`}
          key={item}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          {item}
        </motion.a>
      ))} */}
        <motion.a
          href={'/home'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Home
        </motion.a>
        <motion.a
          href={'/home#Proshows'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Proshows
        </motion.a>

        <motion.a
          href={'/eventcategories'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Events
        </motion.a>
        <motion.a
          href={'/home#Adzone'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Ad-Zone
        </motion.a>
        <motion.a
          href={'/home#WorldFest'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          WorldFests
        </motion.a>
        <motion.a
          href={'/home#Spotlight'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          SpotLights
        </motion.a>

        <motion.a
          href={'/sponsors'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Sponsors
        </motion.a>
        <motion.a
          href={'/rules'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Rules
        </motion.a>
        <motion.a
          href={'/Schedule'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Schedule
        </motion.a>
        <motion.a
          href={'/map'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Map Of Saarang
        </motion.a>
        <motion.a
          href={'/contact'}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Contact Us
        </motion.a>

      <motion.a 
        variants={itemVariants}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}>
            {authContext && authContext.state.user ? (
        <a className='login'
          onClick={() => {
            logoutMutation();
          }}>
          Logout
        </a>
      ) : (
        <>
          <a className='login' href='https://join.saarang.org/auth'>Login/Register</a>
        </>)
      }
      </motion.a>
    </motion.div>
  );
};

export default Links;