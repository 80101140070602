import { useState,useEffect,useRef } from "react";
import { motion } from "framer-motion";
import BMSlinks from "../../../components/bmslinks/bmslinks";
import { NULL } from "sass";



const Proshows = () => {
  

  const [positionIndexes, setPositionIndexes] = useState([0, 1, 2, 3, 4, 5]);
  const [isInView, setIsInView] = useState(false);
  const carouselRef = useRef(null); // Ref for the carousel element
  const [BMSdisp, setBMSdisp] = useState(false);

  const lap=(window.innerWidth > 738);


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.5 } // Adjust this value based on how much of the element should be in view to trigger autoplay
    );

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => {
      if (carouselRef.current) {
        observer.unobserve(carouselRef.current);
      }
    };
  }, [carouselRef]);


  useEffect(() => {
    if (isInView) {
      const interval = setInterval(handleNext, 2000); // Autoplay interval
      return () => clearInterval(interval);
    }
  }, [isInView]);


  const handleNext = () => {
    setPositionIndexes((prevIndexes) => {
      const updatedIndexes = prevIndexes.map(
        (prevIndex) => (prevIndex + 1) % 6
      );
      return updatedIndexes;
    });
  };


  const handleBack = () => {
    setPositionIndexes((prevIndexes) => {
      const updatedIndexes = prevIndexes.map(
        (prevIndex) => (prevIndex + 4) % 5
      );

      return updatedIndexes;
    });
  };

  const images = ['https://iili.io/JuNiPJj.png',//Pop Night
                  'https://iili.io/JTS7QTb.jpg', //Rock Night 
                  "https://iili.io/JTS7ive.jpg", //EDM Night
                  "https://iili.io/J78zUAJ.webp", //Comedy Show
                  "https://iili.io/J7SK5p2.webp",//Classical Night
                  "https://iili.io/JuaGeh7.jpg",//Choreo Night
                 ];

  const positions = ["center", "left1", "left", "right", "right1" ,"invisible"];


  const imageVariants = {
    center: { x: "0%", scale: 1, zIndex: 5 },
    left1: { x: "-80%", scale: 0.7, zIndex: 3 },
    left: { x: "-120%", scale: 0.5, zIndex: 2 },
    right: { x: "120%", scale: 0.5, zIndex: 1 },
    right1: { x: "80%", scale: 0.7, zIndex: 3 },
    invisible:{x:"150%",scale:0.5,zIndex:-1}
  };



  return (
    <div className="proshow-pages" ref={carouselRef} >
      
       <video  className='proshow-bg' autoPlay muted  height={lap?"auto":"100%"} width={lap?'100%':'auto'}  loop controls={false}  playsInline>
          <source src={(lap)?'https://d1kbtnj9hqaggx.cloudfront.net/proshow.mp4':'https://d1kbtnj9hqaggx.cloudfront.net/proshow-mob.mp4'}type="video/mp4"  />
          Your browser does not support the video tag.
        </video> 
      
      <motion.div className="proshow-heading" >
        <div>Proshows</div>
       
      </motion.div>
      
      <motion.div className=" proshow-carousel">
          {images.map((image, index) => (
            <motion.img
            key={index}
            src={image}
            alt={image}
            className="rounded-[12px]"
            initial="center"
            animate={positions[positionIndexes[index]]}
            variants={imageVariants}
            transition={{ duration: 0.5 }}
            style={{ height:(window.innerWidth > 738)? "60%" : "30%", position: "absolute" }}
            />
          ))}
      </motion.div>
      <div className="proshow-buttons">
        <div className="handle-buttons">
{/* 
            <div onClick={handleBack}>&lt;</div>
          <div onClick={handleNext}>&gt;</div> */}
        
        </div>
        
        <div className="booking-button"><a href="https://sales.saarang.org/" target="_blank" >Sales Portal</a></div>
        <div className='booking-button' onClick={() => {setBMSdisp(!BMSdisp)}}>Book My Show</div>
      </div>
          {BMSdisp?<BMSlinks setBMSdisp={setBMSdisp}/>:null}
            


    </div>
  );
};

export default Proshows;