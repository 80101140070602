import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import * as constants from '../../../constant';
import PortalContext from "../../../contexts/PortalContext";
import AuthContext from "../../../contexts/AuthContext";
import {  useLogoutMutation } from "../../../generated/graphql";



function MainPage() {
    const [currentBGIndex, setCurrentBGIndex] = useState(0);

    const navigate=useNavigate();

    

    const images = (window.innerWidth > 738)?
    [
      'https://iili.io/JA3lUyQ.jpg',
      'https://iili.io/JA3V6tR.jpg',//For PC Background
      'https://iili.io/JA3lS8x.jpg',
      'https://iili.io/JA3l8aj.jpg',
      'https://iili.io/JA3lOGe.jpg',
      'https://iili.io/JA3ljZ7.jpg',
      'https://iili.io/JA3lhjS.jpg',
    ]:
    [                                
      'https://iili.io/JA3GIVI.jpg',//For Mobile Devices Background
      'https://iili.io/JA3GzoN.jpg',
      'https://iili.io/JA3Gotp.jpg',
      'https://iili.io/JA3GCAv.jpg',
      'https://iili.io/JA3GfSa.jpg',
      'https://iili.io/JA3GKcg.jpg',
      'https://iili.io/JA3GFKF.jpg',
      'https://iili.io/JA3G2P1.jpg',
      'https://iili.io/JA3GdMP.jpg',
      'https://iili.io/JA3GJoB.jpg',
      'https://iili.io/JA3EywQ.jpg',
    ];

    const [imagesPreloaded, setImagesPreloaded] = useState(false);

  // Preload images
  useEffect(() => {
    let loadedImages = 0;
    images.forEach(image => {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === images.length) {
          setImagesPreloaded(true); // Set to true when all images are loaded
        }
      };
    });
  }, []);

    const controls = useAnimation();

    useEffect(() => {
      if (!imagesPreloaded) return;
  
      const interval = setInterval(() => {
        setCurrentBGIndex(prevIndex =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000); // Change every 5000 milliseconds
  
      return () => clearInterval(interval);
    }, [imagesPreloaded]);


    useEffect(() => {
      // Start the animation when currentBGIndex changes
      controls.start({
        background: `url('${images[currentBGIndex]}') no-repeat center center / cover`,
        transition: { duration: 2, ease: "easeInOut" } // Smooth transition
      });
    }, [currentBGIndex, controls]);

    const textVariants = {
        initial: {
          x: -500,
          opacity: 0,
        },
        animate: {
          x: 0,
          opacity: 1,
          transition: {
            duration: 1,
            staggerChildren: 0.1,
          },
        },
        scrollButton: {
          opacity: 0,
          y: 10,
          transition: {
            duration: 2,
            repeat: Infinity,
          },
        },
      };
    
    const authContext =useContext(AuthContext);
    const portal = useContext(PortalContext);
    const [logoutMutation,{data,loading,error}] = useLogoutMutation();
    useEffect(() => {
      // console.log("L", data);
      if (data) {
        if (data.logout === 1) {
          if (authContext) {
            authContext.signOut();
          }
        }
      }
    }, [data]);

    return ( 
          <motion.div className="main-page" initial={{ background: `url('${images[currentBGIndex]}')` }}
          animate={controls}>
            <div className='registration-button'>
                {authContext && authContext.state.user ? (
                  <a className='login'
                    onClick={() => {
                      logoutMutation();
                    }}>
                    Logout
                  </a>
                ) : (
                  <>
                    <a className='login' href='https://join.saarang.org/auth'>Login/Register</a>
                  </>)
                }
            </div>
            <div className="schedule-button"><a href="/schedule">Schedule</a></div>
             <div className="main-buttons">
                <a className="proshow-button main-button" href="#Proshows" > <img src='https://iili.io/Juaf2yB.png'/> <div> Proshow Tickets </div> </a>
                <a className="events-button main-button"  href="/eventcategories"> <img src='https://iili.io/JuaffZF.png '   /><div> Event Registration </div> </a>
                <a className="hospi-button main-button" href="https://join.saarang.org/accommodation" target="_blank"> <img src='https://iili.io/JuafFTP.png'/> <div> Accomodation </div> </a>
                <a className="merch-button main-button"href="https://sales.saarang.org/merch" target="_blank"> <img src='https://iili.io/JuafKj1.png'/> <div>Merchandise</div>  </a>
                <a className="map-button main-button"href="/map" > <img src='https://iili.io/J7eGOLg.png'/> <div>Map Of Saarang</div>  </a>
             </div>
                
                <motion.img 
                className='saarang-text'
                src={constants.SAARANG_MAIN}
                variants={textVariants}
                initial="initial"
                animate="animate"
                />

                <div className="dates">10th-14th January 2024</div>

                <motion.img
                className='scroll-Button'
                variants={textVariants}
                animate="scrollButton"
                src={constants.SCROLL}
                alt=""
                />
          </motion.div>
     );
}

export default MainPage;