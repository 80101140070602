import React, { useContext } from 'react';
import './footer.css'
import { FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa';
import * as constants from '../../constant';
import PortalContext from '../../contexts/PortalContext';
import { useLocation } from 'react-router-dom';

const Footer = () => {

    const portal = useContext(PortalContext);



    const location = useLocation();
    const currentPath = location.pathname;

    const path=/\/home(#.*)?/;
    return (
        <div className="footer" 
        style={{display:(currentPath === "/")?'none':'block'}}
        >
            <div className="footer-top">
                <div>
                    {/* photo (dummy) */}
                </div>
                <div className='right'>
                    {/* <div>
                        <h4>Mobile App</h4>
                        <p>Features</p>
                        <p>Live share</p>
                        <p>Video record</p>
                    </div> */}
                    {/* <div>
                        <h4>Community</h4>
                        <a href='https://join.saarang.org/'>The portal</a>
                    </div> */}
                    <div>
                        {/* <h4>Company</h4> */}
                        <p><a style={{'fontWeight': 'bold'}} href='https://forms.gle/SNRau6bvPM9BRa9PA' target="_blank">Get Support</a></p>
                        <p><a  style={{'fontWeight': 'bold'}} href='https://saarang.org/contact'>Contact Us</a></p>
                        {/* <p><a href='https://forms.gle/SNRau6bvPM9BRa9PA' target="_blank">Get Support</a></p>
                        <p><a href='https://saarang.org/contact'>Contact Us</a></p> */}
                        {/* <p>History</p> */}
                    </div>
                    <div className='buttons'>
                        {/* <a href={`https://localhost:3006/signup/${portal}`}>Register</a>
                        <a href={`https://localhost:3006/login/${portal}`}>Login</a> */}

                        <a href='https://join.saarang.org/auth'>Register</a>
                        <a href='https://join.saarang.org/auth'>Login</a>
                    </div>
                </div>
            </div>
            <hr />
            <div className="footer-bottom">
                <div>&#169;Designed by MAD & Developed by DevOps Team, Saarang 2024</div>
                <div className='right'>
                    <p>Follow us:</p>
                    <div className='social-media'><a href="https://www.youtube.com/user/iitmsaarang"><FaYoutube className='icons' /></a></div>
                    <div className='social-media'><a href="https://www.instagram.com/saarang_iitmadras"><FaInstagram className='icons' /></a></div>
                    <div className='social-media'><a href="https://www.facebook.com/saarangiitm/"><FaFacebook className='icons' /></a></div>
                    <div className='social-media'><a href="https://twitter.com/saarang_iitm"><img src={constants.X_ICON} className='icons'/></a></div>
                </div>
            </div>
        </div>
    )
}

export default Footer;